(function ($) {
  Drupal.behaviors.selectLanguageV1 = {
    attach: function (context, settings) {
      $('.js-switch-lang-link', context).bind('click', function (event) {
        event.preventDefault();
        var prefix = $(this).attr('data-mp-lang');
        var postfixes = $(this).parent().attr('data-mp-base-lang');

        switchLanguage(prefix, postfixes);
      });

      function switchLanguage(lang, postfixesdata) {
        var postfixes = JSON.parse(postfixesdata);
        var lang2 = $('.header-language-select__link02').attr('data-mp-lang');
        var lang3 = $('.header-language-select__link03').attr('data-mp-lang');
        var lang2_val = postfixes[lang2];
        var lang3_val = postfixes[lang3];
        var path = window.location.pathname.replace(lang2_val, postfixes[lang]);

        if (path == window.location.pathname) {
          path = window.location.pathname.replace(lang3_val, postfixes[lang]);
        }

        var pathArray = path.split('/');
        var postfixesArray = postfixes[lang].split('/');
        var site_path = '';

        if (pathArray[1] == postfixesArray[1]) {
          site_path = window.location.host + path;
        } else {
          site_path = window.location.host + postfixes[lang] + path;
        }

        site_path = site_path.replace(/\/+/g, '/');
        var url = window.location.protocol + '//' + site_path;
        var full_url = window.location.href;
        var appends = '';
        var full_url_parts = '';

        if (full_url.indexOf('#') > 0) {
          full_url_parts = full_url.split('#');
          appends = '#' + full_url_parts[1];
        } else if (full_url.indexOf('?') > 0) {
          full_url_parts = full_url.split('?');
          appends = '?' + full_url_parts[1];
        }
        window.location.href = url + appends;
      }
    }
  };
})(jQuery);
